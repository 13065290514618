<template>
  <article class="new-question">
    <div class="site-wrapper rythm-v">
      <header>
        <h1>Nouvelle question</h1>
      </header>

      <section class="section--new-question rythm-v">
        <header>
          <h2>Étape 1 – Thématiques</h2>
        </header>

        <div class="card shadow rythm-v" v-if="themeList">
          <form @submit.prevent="updateQuestionData()">
            <section class="rythm-v">
              <header>
                <h3>
                  Thématique générale <span class="required small-text">*</span>
                </h3>
              </header>

              <div class="input-group--inline">
                <label
                  v-for="theme in rootThemes"
                  :key="theme.id"
                  :for="`primary_theme_id[${theme.id}]`"
                >
                  <input
                    type="radio"
                    name="primary_theme_id"
                    :id="`primary_theme_id[${theme.id}]`"
                    :value="theme.id"
                    v-model="themes.primaryRootThemeID"
                    @change="updatePrimaryRootTheme(theme.id)"
                  />
                  <span class="caption">{{ theme.name }}</span>
                </label>
              </div>

              <div class="sub-themes-wrapper" v-if="themes.primaryThemes">
                <div
                  class="input-group required"
                  v-for="parentTheme in themes.primaryThemes"
                  v-bind:key="`primary_parent_theme_id[${parentTheme.id}]`"
                  :id="`primary_parent_theme_id[${parentTheme.id}]`"
                >
                  <label :for="`select_primary_child_theme[${parentTheme.id}]`"
                    >Sous-thématique de <em>{{ parentTheme.name }}</em> :</label
                  >
                  <!--
									<dropdown-filter
										:optionsList="parentTheme.children"
										:default-option="{value: '', caption: 'Aucune sélection'}"
										select-id="myOption"
										select-name="myOption"
										@change="updatePrimaryThemes($event, parentTheme.id)"
									/>-->

                  <select
                    :name="`select_primary_child_theme[${parentTheme.id}]`"
                    :id="`select_primary_child_theme[${parentTheme.id}]`"
                    @change="updatePrimaryThemes($event, parentTheme.id)"
                    required
                  >
                    <option value="">Aucune sélection</option>
                    <option
                      v-for="child in parentTheme.children"
                      v-bind:key="`select_primary_option_theme_id[${child.id}]`"
                      :value="child.id"
                      :selected="isSelectedTheme('primary', child.id)"
                      >{{ child.name }}</option
                    >
                  </select>
                </div>
              </div>
            </section>

            <section class="rythm-v">
              <header>
                <h3>
                  Thématique secondaire <em class="small-text">(facultatif)</em>
                </h3>
              </header>

              <div class="input-group--inline">
                <label>
                  <input
                    type="radio"
                    name="secondary_theme_id"
                    value=""
                    v-model="themes.secondaryRootThemeID"
                    @change="updateSecondaryRootTheme(0)"
                  />
                  <span class="caption">Aucune</span>
                </label>

                <label
                  v-for="theme in rootThemes"
                  :key="theme.id"
                  :for="`secondary_theme_id[${theme.id}]`"
                >
                  <input
                    type="radio"
                    name="secondary_theme_id"
                    :id="`secondary_theme_id[${theme.id}]`"
                    :value="theme.id"
                    v-model="themes.secondaryRootThemeID"
                    @change="updateSecondaryRootTheme(theme.id)"
                  />
                  <span class="caption">{{ theme.name }}</span>
                </label>
              </div>

              <div class="sub-themes-wrapper" v-if="themes.secondaryThemes">
                <div
                  class="input-group required"
                  v-for="parentTheme in themes.secondaryThemes"
                  v-bind:key="`secondary_parent_theme_id[${parentTheme.id}]`"
                  :id="`secondary_parent_theme_id[${parentTheme.id}]`"
                >
                  <label
                    :for="`select_secondary_child_theme[${parentTheme.id}]`"
                    >Sous-thématique de <em>{{ parentTheme.name }}</em> :</label
                  >
                  <select
                    :name="`select_secondary_child_theme[${parentTheme.id}]`"
                    :id="`select_secondary_child_theme[${parentTheme.id}]`"
                    @change="updateSecondaryThemes($event, parentTheme.id)"
                    required
                  >
                    <option value="">Aucune sélection</option>
                    <option
                      v-for="child in parentTheme.children"
                      v-bind:key="
                        `select_secondary_option_theme_id[${child.id}]`
                      "
                      :value="child.id"
                      :selected="isSelectedTheme('secondary', child.id)"
                      >{{ child.name }}</option
                    >
                  </select>
                </div>
              </div>
            </section>

            <section>
              <div class="input-group--inline-controls">
                <button
                  class="button--primary"
                  type="submit"
                  :disabled="!themes.selectedPrimaryThemes.length"
                >
                  <span class="caption">Étape suivante</span>
                  <svg
                    class="icon"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="none"
                  >
                    <path
                      fill="currentColor"
                      fill-rule="evenodd"
                      d="M8.70711 0.292893C8.31658 -0.0976311 7.68342 -0.0976311 7.29289 0.292893C6.90237 0.683418 6.90237 1.31658 7.29289 1.70711L12.5858 7H1C0.447715 7 0 7.44772 0 8C0 8.55229 0.447715 9 1 9H12.5858L7.29289 14.2929C6.90237 14.6834 6.90237 15.3166 7.29289 15.7071C7.68342 16.0976 8.31658 16.0976 8.70711 15.7071L15.7071 8.70711C16.0976 8.31658 16.0976 7.68342 15.7071 7.29289L8.70711 0.292893Z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </button>
              </div>
            </section>
          </form>
        </div>
      </section>
    </div>
  </article>
</template>

<style lang="scss">
@import "src/scss/question.scss";
</style>

<script>
import buttonResetQuestion from "@/components/buttonResetQuestion";
import DropdownFilter from "@/components/DropdownFilter";

// Create component
export default {
  name: "questionEditThemes",

  components: { buttonResetQuestion, DropdownFilter },

  props: ["questionID"],

  data: function() {
    return {
      isDraft: false,

      // Themes
      themeList: null,
      rootThemes: null,

      // Question Themes
      themes: {
        // <select>'s data
        primaryRootThemeID: 0,
        secondaryRootThemeID: 0,

        primaryThemes: null,
        secondaryThemes: null,

        // user data
        selectedPrimaryThemes: [],
        selectedSecondaryThemes: [],
      },
    };
  },

  methods: {
    // Theme selection and dynamic render of the <select>'s
    handleSelectedTheme: function(selectedOptionValue, parentID) {
      console.log("handleSelectedTheme", selectedOptionValue, parentID);
    },

    // Set selected options
    isSelectedTheme: function(parentName, themeID) {
      if (parentName === "primary")
        return !!this.themes.selectedPrimaryThemes.find(
          (el) => el.id == themeID
        );
      if (parentName === "secondary")
        return !!this.themes.selectedSecondaryThemes.find(
          (el) => el.id == themeID
        );
      return false;
    },

    // Save local question data
    updateQuestionData: function() {
      let payload = {
        questionID: this.questionID,
        dataFragment: { themes: this.themes },
      };
      this.$store
        .dispatch("SAVE_QUESTION_DRAFT", payload)
        .then(() =>
          this.$router.push(`/edit-question-details/${this.questionID}`)
        )
        .catch((error) => {
          console.error("SAVE_QUESTION_DRAFT error");
        });
    },

    // find in full tree
    findDeep: function(tree, nodeId) {
      for (let node of tree) {
        if (parseInt(node.id) === parseInt(nodeId)) return node;

        if (node.children) {
          let desiredNode = this.findDeep(node.children, nodeId);
          if (desiredNode) return desiredNode;
        }
      }
      return null;
    },

    // get a theme from the full list
    getTheme: function(themeID) {
      return this.findDeep(this.themeList, themeID);
    },

    // set the top parent theme (from the radio inputs)
    updatePrimaryRootTheme: function(themeID) {
      // reset all selects and previously selected themes
      this.themes.primaryThemes = [];
      this.themes.selectedPrimaryThemes = [];

      // set first primary theme
      let parentTheme = this.getTheme(themeID);

      let newValue = {
        parent_id: 0,
        id: themeID,
        name: parentTheme.name,
      };

      // If the selected item exists already, update its value
      let storedSelectIndex = this.themes.selectedPrimaryThemes.findIndex(
        (el) => el.parent_id === themeID
      );
      if (storedSelectIndex >= 0) {
        this.$set(
          this.themes.selectedPrimaryThemes,
          storedSelectIndex,
          newValue
        );
        this.themes.selectedPrimaryThemes.splice(storedSelectIndex + 1);
      } else this.themes.selectedPrimaryThemes.push(newValue);

      // Set the first child theme selector
      if (parentTheme.children) this.themes.primaryThemes.push(parentTheme);
    },

    // set the top parent theme (from the radio inputs)
    updateSecondaryRootTheme: function(themeID) {
      // reset all selects and previously selected themes
      this.themes.secondaryThemes = [];
      this.themes.selectedSecondaryThemes = [];

      // If themeID = 0 : no theme selected
      if (themeID === 0) return;

      // set first primary theme
      let parentTheme = this.getTheme(themeID);

      let newValue = {
        parent_id: 0,
        id: themeID,
        name: parentTheme.name,
      };

      // If the selected item exists already, update its value
      let storedSelectIndex = this.themes.selectedSecondaryThemes.findIndex(
        (el) => el.parent_id === themeID
      );
      if (storedSelectIndex >= 0) {
        this.$set(
          this.themes.selectedSecondaryThemes,
          storedSelectIndex,
          newValue
        );
        this.themes.selectedSecondaryThemes.splice(storedSelectIndex + 1);
      } else this.themes.selectedSecondaryThemes.push(newValue);

      // Set the first child theme selector
      if (parentTheme.children) this.themes.secondaryThemes.push(parentTheme);
    },

    // Theme selection and dynamic render of the <select>'s
    updatePrimaryThemes: function(event, parentID) {
      parentID = parseInt(parentID);
      let themeID = parseInt(event.target.value);

      // Reset previously selected theme
      // let previouslySelectedTheme = this.themes.primaryThemes.children.findIndex(el => el.selected);
      // if (previouslySelectedTheme > -1)
      // 	this.$set(this.themes.primaryThemes.children[previouslySelectedTheme], "selected", false);

      // THE SELECTION IS CANCELED (EMPTY THEMEID <OPTION> VALUE)

      // - remove the selection
      // - remove the next children
      if (!themeID && parentID) {
        let storedSelectIndex = this.themes.selectedPrimaryThemes.findIndex(
          (el) => el.parent_id === parentID
        );
        this.themes.selectedPrimaryThemes.splice(storedSelectIndex);

        let currentChildIndex = this.themes.primaryThemes.findIndex(
          (el) => parseInt(el.parent_id) === parentID
        );
        if (currentChildIndex < 0) return;
        else this.themes.primaryThemes.splice(currentChildIndex);

        let currentChild = this.themes.primaryThemes.find(
          (el) => parseInt(el.parent_id) === parentID
        );

        return;
      }

      // A NEW THEME IS SELECTED

      // Check if there is already a child theme selected for this parentID
      let currentChildIndex = this.themes.primaryThemes.findIndex(
        (el) => parseInt(el.parent_id) === parentID
      );

      // Then remove this child and all next children
      if (currentChildIndex >= 0)
        this.themes.primaryThemes.splice(currentChildIndex);

      // Get the selected theme object
      let parentTheme = this.getTheme(themeID);

      // If this parentTheme has children, push it to this.themes.primaryThemes and render another <select>
      if (parentTheme.children) this.themes.primaryThemes.push(parentTheme);

      // Update the currently selected theme
      // let selectedTheme = this.themes.primaryThemes.findIndex(el => el.id === themeID);
      // console.log("selectedTheme", selectedTheme);
      // selectedTheme.selected = true;

      // Now, store the selected theme IDs to insert in database
      let newValue = {
        parent_id: parentID,
        id: themeID,
        name: parentTheme.name,
      };

      // If the select exists already, update its value
      let storedSelectIndex = this.themes.selectedPrimaryThemes.findIndex(
        (el) => el.parent_id === parentID
      );
      if (storedSelectIndex >= 0) {
        this.$set(
          this.themes.selectedPrimaryThemes,
          storedSelectIndex,
          newValue
        );
        // then remove the next children
        this.themes.selectedPrimaryThemes.splice(storedSelectIndex + 1);
      } else this.themes.selectedPrimaryThemes.push(newValue);
    },

    // Theme selection and dynamic render of the <select>'s
    updateSecondaryThemes: function(event, parentID) {
      parentID = parseInt(parentID);
      let themeID = parseInt(event.target.value);

      // THE SELECTION IS CANCELED (EMPTY THEMEID <OPTION> VALUE)

      // - remove the selection
      // - remove the next children
      if (!themeID && parentID) {
        let storedSelectIndex = this.themes.selectedSecondaryThemes.findIndex(
          (el) => el.parent_id === parentID
        );
        this.themes.selectedSecondaryThemes.splice(storedSelectIndex);

        let currentChildIndex = this.themes.secondaryThemes.findIndex(
          (el) => parseInt(el.parent_id) === parentID
        );
        if (currentChildIndex < 0) return;
        else this.themes.secondaryThemes.splice(currentChildIndex);

        let currentChild = this.themes.secondaryThemes.find(
          (el) => parseInt(el.parent_id) === parentID
        );

        return;
      }

      // A NEW THEME IS SELECTED

      // Check if there is already a child theme selected for this parentID
      let currentChildIndex = this.themes.secondaryThemes.findIndex(
        (el) => parseInt(el.parent_id) === parentID
      );

      // Then remove this child and all next children
      if (currentChildIndex >= 0)
        this.themes.secondaryThemes.splice(currentChildIndex);

      // Get the selected theme object
      let parentTheme = this.getTheme(themeID);

      // If this parentTheme has children, push it to this.themes.secondaryThemes and render another <select>
      if (parentTheme.children) this.themes.secondaryThemes.push(parentTheme);

      // Now, store the selected theme IDs to insert in database
      let newValue = {
        parent_id: parentID,
        id: themeID,
        name: parentTheme.name,
      };

      // If the select exists already, update its value
      let storedSelectIndex = this.themes.selectedSecondaryThemes.findIndex(
        (el) => el.parent_id === parentID
      );
      if (storedSelectIndex >= 0) {
        this.$set(
          this.themes.selectedSecondaryThemes,
          storedSelectIndex,
          newValue
        );
        // then remove the next children
        this.themes.selectedSecondaryThemes.splice(storedSelectIndex + 1);
      } else this.themes.selectedSecondaryThemes.push(newValue);
    },

    setupThemes: function(themeList) {
      // set the global tree and the root themes
      this.themeList = themeList;
      this.rootThemes = themeList.filter((el) => !el.parent_id);
    },

    // Fetch the themes from the API
    getThemes: function() {
      this.$store
        .dispatch("GET_THEMES")
        .then((response) => {
          let themeList = response;
          this.setupThemes(themeList);
        })
        .catch((error) => {
          console.log("getThemes error…", error);
          // eventHub.$emit("network-error", error.response);
        });
    },
  },

  mounted() {
    // Fetch the full theme tree
    this.getThemes();

    // NOTE: no drafts/question edit for now. Reset local storage
    // this.$store.dispatch("RESET_QUESTION_DRAFT");

    // Check if there is a local draft to populate themes
    if (parseInt(this.questionID)) {
      let questionData = this.$store.state.questionDraft;
      if (null !== questionData && questionData.themes) {
        this.themes = questionData.themes;
        this.isDraft = true;
      }
    } else {
      this.$store.dispatch("RESET_QUESTION_DRAFT");
    }
    // if (!parseInt(this.questionID)) {
    // 	let questionData = this.$store.state.questionDraft;

    // 	if (null !== questionData && questionData.themes) {
    // 		this.themes = questionData.themes;
    // 		this.isDraft = true;
    // 	}
    // }
  },
};
</script>
