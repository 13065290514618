<template>
	<div class="dropdown-wrapper">
		<details class="dropdown" data-dropdown="options">
			<summary>
				<span>{{ selectedOptionCaption }}</span>
			</summary>

			<div class="optgroup">
				<label>
					<input
						type="radio"
						:name="selectName"
						:id="`${selectName}_0`"
						:value="defaultOption.value"
						:caption="defaultOption.caption"
						@change="handleChange"
					/>
					{{ defaultOption.caption }}
				</label>

				<label v-for="option in optionsList" v-bind:key="option.id">
					<input
						type="radio"
						:name="selectName"
						:id="`${selectName}_${option.id}`"
						:checked="option.selected"
						:value="option.id"
						:caption="option.name"
						@change="handleChange"
					/>
					{{ option.selected }} {{ option.name }}
				</label>
			</div>
		</details>

		<!--
		<select @change="handleChange" v-model="selectedOption" :id="selectId" :name="selectName">
			<option :value="defaultOption.value">{{ defaultOption.caption }}</option>
			<option v-for="option in optionsList" v-bind:key="option.id" :value="option.id" :selected="option.selected">{{
				option.name
			}}</option>
		</select>
		--></div>
</template>

<style lang="scss">
@import "../scss/01-settings/settings.colors";

.dropdown {
	background-color: $color-white;
	border: 1px solid var(--color-border);
	border-radius: 6px;
	position: relative;
	user-select: none;
}

.dropdown summary {
	padding: 0.5em;
	outline: none;
}

.dropdown .optgroup {
	border-radius: 6px;
	display: flex;
	flex-flow: row wrap;
	align-items: center;
	position: relative;
	background-color: $color-white;
	border: 1px solid var(--color-border);
	box-shadow: 0 3px 6px var(--color-border);
}

.dropdown:focus,
.dropdown:hover,
.dropdown:focus-within {
	border-color: $color-accent;
}

.dropdown[open] .optgroup {
	position: absolute;
	margin-top: 0.5rem;
	z-index: 1;
}

.dropdown:not([open]) input:checked + .caption {
	position: absolute;
	top: -1rem;
}

.dropdown label {
	padding: 0.5em;
	flex-basis: 100%;
}

.dropdown input:focus,
.dropdown label:focus,
.dropdown label:hover {
	background-color: $color-accent;
}

.dropdown input + .caption {
	padding-left: 0.5em;
}

.dropdown,
.dropdown label {
	cursor: pointer;
}

.dropdown input:checked,
.dropdown input:checked + label {
	outline: 1px dotted;
}

// .dropdown input {
// 	clip: rect(1px, 1px, 1px, 1px);
// 	clip-path: inset(50%);
// 	height: 1px;
// 	width: 1px;
// 	margin: -1px;
// 	overflow: hidden;
// 	padding: 0;
// 	position: absolute;
// }
</style>

<script>
export default {
	name: "DropdownFilter",

	props: {
		selectId: {type: String},
		selectName: {type: String},

		optionsList: {
			type: Array,
			default: function() {
				return [];
			}
		},

		defaultOption: {
			type: Object,
			default: function() {
				return {
					value: "",
					caption: "Empty selection"
				};
			}
		}
	},

	data: function() {
		return {
			selectedOption: null,
			selectedOptionCaption: ""
		};
	},

	mounted: function() {
		this.selectedOption = this.optionsList.find(el => el.selected);
	},

	methods: {
		handleChange: function(event) {
			// console.log("handleChange", event);
			this.selectedOptionCaption = event.target.getAttribute("caption");
			this.selectedOption = this.optionsList.find(el => el.selected);
			this.$emit("change", event.target.value);
		}
	}
};
</script>
